import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'

//scss
import '../../scss/block/ecu.scss'

//data
const getData = graphql`
  {
    c: allContentfulEcu(
      sort: { fields: order, order: ASC }
      filter: { order: { ne: 0 } }
    ) {
      nodes {
        title
        dsc {
          dsc
        }
        img {
          fixed {
            ...GatsbyContentfulFixed
          }
        }
      }
    }
    d: contentfulEcu(order: { eq: 0 }) {
      title
      dsc {
        dsc
      }
      img {
        fixed {
          ...GatsbyContentfulFixed
        }
      }
    }
  }
`
const Ecu = () => {
  const data = useStaticQuery(getData)
  const [pos, setPos] = useState(-1)
  console.log(pos)
  return (
    <div className='ecu'>
      <div className='container flex'>
        <div className='ecuTitle'>
          <Image fixed={data.d.img.fixed} alt={data.d.title} />
          <h2>{data.d.title}</h2>
          <p>{data.d.dsc.dsc}</p>
        </div>
        <div className='ecuElement'>
          <div className='img'>
            {data.c.nodes.map((item, index) => {
              return (
                <button
                  onClick={() => setPos((pos) => index)}
                  key={index}
                  className={`${index === pos ? 'active' : ''}`}
                >
                  <Image fixed={item.img.fixed} alt={item.title} />
                  <div className='title'>
                    <h2>{item.title}</h2>
                  </div>
                </button>
              )
            })}
          </div>
          <div className='info'>
            {data.c.nodes.map((item, index) => {
              if (item.dsc !== null) {
                return (
                  <p key={index} className={`${index === pos ? 'active' : ''}`}>
                    {item.dsc.dsc}
                  </p>
                )
              } else {
                return false
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ecu
