import React from 'react'
//component
import Ecu from '../components/block/Ecu'
export default function Home() {
  return (
    <>
      <Ecu />
    </>
  )
}
